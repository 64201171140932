import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { Download as DownloadIcon } from '@phosphor-icons/react/dist/ssr/Download';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Upload as UploadIcon } from '@phosphor-icons/react/dist/ssr/Upload';
import dayjs from 'dayjs';

import { config } from '../../../config';
import { IntegrationCard } from '../../../components/app/integration/integration-card';
import { IntegrationFilter } from '../../../components/app/integration/components/integration-filters';
import { useTranslation } from 'react-i18next';

export const metadata = { title: `Integrations | Dashboard | ${config.site.name}` };

export default function Page() {
  const {t} = useTranslation()

  const integrations = [
    {
      id: 'hostex',
      title: t("integration.hostex.title"),
      description: t("integration.hostex.description"),
      logo: '/assets/logo-hostex.jpeg',
      installs: 594,
      updatedAt: dayjs().subtract(12, 'minute').toDate(),
    },
    {
      id: 'ewelink',
      title: t("integration.ewelink.title"),
      description: t("integration.ewelink.description"),
      logo: '/assets/logo-ewelink.jpeg',
      installs: 625,
      updatedAt: dayjs().subtract(43, 'minute').subtract(1, 'hour').toDate(),
    }
  ]

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">{t("integrations")}</Typography>
        </Stack>
      </Stack>
      {/* <IntegrationFilter /> */}
      <Grid container spacing={3}>
        {integrations.map((integration) => (
          <Grid key={integration.id} lg={4} md={6} xs={12}>
            <IntegrationCard integration={integration} />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination count={1} size="small" />
      </Box>
    </Stack>
  );
}
