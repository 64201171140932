import * as React from 'react';

import { formClient } from '../lib/app/form';
import { propertyClient } from '../lib/app/property';
import { reservationClient } from '../lib/app/reservation';
import { integrationClient } from '../lib/app/integration';
import { checkInClient } from '../lib/app/checkin';
import { deviceClient } from '../lib/app/device';
import { logger } from '../lib/default-logger';
import { useUser } from '../hooks/use-user';

export const PropertyContext = React.createContext(undefined);

export function PropertyProvider({ children }){
  const [state, setState] = React.useState({
    properties: null,
    property: null,
    error: null,
    isLoading: true,
  });

  const { user } = useUser();

  const TENANT_ID = user?.["custom:tenant_id"]?? ""

  async function deviceUnlock(tenant_id, reservation_id){ 
    const { data, error } = await deviceClient.unlock(tenant_id, reservation_id)
    
    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, device: data }));

    return { data: data, error: error }
  }

  async function loadCheckIn(tenant_id, reservation_id) {
    const { data, error } = await checkInClient.load(tenant_id, reservation_id)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, checkIn: data }));

    return { data: data, error: error }
  }

  async function updateCheckIn(tenant_id, reservation_id, checked_in_guests) {
    const { data, error } = await checkInClient.update(tenant_id, reservation_id, checked_in_guests)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, checkIn: data }));

    return { data: data, error: error }
  }

  async function loadIntegration(integration_id ) {
    const { data, error } = await integrationClient.load(TENANT_ID, integration_id)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, integration: data }));

    return { data: data, error: error }
  }

  async function createIntegration(integration_id, configuration) {
    const { data, error } = await integrationClient.create(TENANT_ID, integration_id, configuration)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, integration: data }));

    return { data: data, error: error }
  }

  async function updateIntegration(integration_id, configuration) {
    const { data, error } = await integrationClient.update(TENANT_ID, integration_id, configuration)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, integration: data }));

    return { data: data, error: error }
  }

  async function listForms() {
    const { data, error } = await formClient.list(TENANT_ID)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, forms: data }));

    return { data: data, error: error }
  }

  async function loadForm(form_id, tenant_id=TENANT_ID) {
    const { data, error } = await formClient.load(tenant_id, form_id)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, form: data }));

    return { data: data, error: error }
  }

  async function createForm(form_name, custom_fields) {
    const { data, error } = await formClient.create(TENANT_ID, form_name, custom_fields)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, form: data }));

    return { data: data, error: error }
  }

  async function updateForm(form_id, form_name, custom_fields) {
    const { data, error } = await formClient.update(TENANT_ID, form_id, form_name, custom_fields)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, form: data }));

    return { data: data, error: error }
  }

  async function listProperties() {
    const { data, error } = await propertyClient.list(TENANT_ID)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, properties: data }));

    return { data: data, error: error }
  }

  async function loadProperty(property_id, tenant_id=TENANT_ID) {
    const { data, error } = await propertyClient.load(tenant_id, property_id)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, property: data }));

    return { data: data, error: error }
  }

  async function createProperty(property_name, street, apt, neighborhood, city, state, postal_code) {
    const { data, error } = await propertyClient.create(TENANT_ID, property_name, street, apt, neighborhood, city, state, postal_code)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, property: data }));

    return { data: data, error: error }
  }

  async function updateProperty(property_id, property_name, street, apt, neighborhood, city, state, postal_code) {
    const { data, error } = await propertyClient.update(TENANT_ID, property_id, property_name, street, apt, neighborhood, city, state, postal_code)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, property: data }));

    return { data: data, error: error }
  }

  async function listReservations() {
    const { data, error } = await reservationClient.list(TENANT_ID)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, reservations: data }));

    return { data: data, error: error }
  }

  async function loadReservation(reservation_id ) {
    const { data, error } = await reservationClient.load(TENANT_ID, reservation_id)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, reservation: data }));

    return { data: data, error: error }
  }


  async function createReservation(reservation_id, number_of_guests, check_in_date, check_out_date, property, guest) {
    const { data, error } = await reservationClient.create(TENANT_ID, reservation_id, number_of_guests, check_in_date, check_out_date, property, guest)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, reservation: data }));

    return { data: data, error: error }
  }

  async function updateReservation(reservation_id, number_of_guests, check_in_date, check_out_date, property, guest) {
    const { data, error } = await reservationClient.update(TENANT_ID, reservation_id, number_of_guests, check_in_date, check_out_date, property, guest)

    if(error)
    {
      logger.error(error)
      return { data: null, error: error };
    }

    setState((prev) => ({ ...prev, reservation: data }));

    return { data: data, error: error }
  }

  return <PropertyContext.Provider value={{ ...state, 
    deviceUnlock: deviceUnlock,
    loadCheckIn: loadCheckIn,
    updateCheckIn: updateCheckIn,
    loadIntegration: loadIntegration,
    createIntegration: createIntegration,
    updateIntegration: updateIntegration,
    listProperties: listProperties,
    loadProperty: loadProperty,
    createProperty: createProperty,
    updateProperty: updateProperty,
    listForms: listForms,
    loadForm: loadForm,
    createForm: createForm,
    updateForm: updateForm,
    listReservations: listReservations,
    loadReservation: loadReservation,
    createReservation: createReservation,
    updateReservation: updateReservation
 }}>{children}</PropertyContext.Provider>;
}

export const PropertyConsumer = PropertyContext.Consumer;
