import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react/dist/ssr/Download';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Upload as UploadIcon } from '@phosphor-icons/react/dist/ssr/Upload';
import dayjs from 'dayjs';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { config } from '../../../config';
import { PropertyDetailsForm } from '../../../components/app/property/components/property-details-form';

export const metadata = { title: `Create property | Dashboard | ${config.site.name}` };

export function PropertyCreate() {
    const [property, setProperty] = React.useState()
    const {t} = useTranslation();

    return (
      <Stack spacing={3}>
      <div>
      <Typography variant="h4">{t("property")}</Typography>
      </div>
      <Grid container spacing={3}>
        <Grid lg={12} md={12} xs={12}>
          <PropertyDetailsForm/>
        </Grid>
      </Grid>
    </Stack>
  );
}