'use client';

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Pen as PenIcon } from '@phosphor-icons/react/dist/ssr/Pen';

import { useSelection } from '../../../../hooks/use-selection';
import { paths } from '../../../../paths';
import { useTranslation } from 'react-i18next';

function noop() {
  // do nothing
}

export function ReservationTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
}) {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <Card>
      <Box sx={{ overflowX: 'auto' }}>
        <Table sx={{ minWidth: '800px' }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("reservation.id")}</TableCell>
              <TableCell>{t("reservation.status")}</TableCell>
              <TableCell>{t("reservation.name")}</TableCell>
              <TableCell>{t("reservation.check_in_date")}</TableCell>
              <TableCell>{t("reservation.check_out_date")}</TableCell>
              <TableCell>{t("reservation.number_of_guests")}</TableCell>
              <TableCell>{t("reservation.property")}</TableCell>
              <TableCell>{t("reservation.booked_at")}</TableCell>
              <TableCell>{t("reservation.action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow hover key={row.reservation_id} >
                  <TableCell>
                      <Typography variant="subtitle2">{row.reservation_id}</Typography>
                  </TableCell>
                  <TableCell>
                    {row.reservation_status == "checked_in" ?
                      <Chip label={t('reservation.checked_in')} color="success" size="small" />
                      :
                      <Chip label={t('reservation.pending_check_in')} color="warning" size="small" />
                    }
                  </TableCell>
                  <TableCell>
                      <Typography variant="subtitle2">{row.guest?.name}</Typography>
                  </TableCell>
                  <TableCell>                    
                    {dayjs(row.check_in_date).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                  {dayjs(row.check_out_date).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>{row.number_of_guests}</TableCell>
                  <TableCell>{row.property_detail?.property_name}</TableCell>
                 
                  <TableCell>    
                    {dayjs(row.reservation_date).format("DD/MM/YYYY HH:MM")}
                  </TableCell>

                  <TableCell>
                    <Button 
                      startIcon={<PenIcon fontSize="var(--icon-fontSize-md)" />} 
                      variant="outlined"
                      onClick={() => {
                        navigate(paths.app.reservations + "/" + row.reservation_id)
                      }}
                    >
                      {t("reservation.edit")}
                    </Button>

                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <TablePagination
        component="div"
        page={page}
        rowsPerPage={rowsPerPage}
        count={count}
        onPageChange={(event, newPage) => onPageChange(newPage)}
        onRowsPerPageChange={(event) => onRowsPerPageChange(parseInt(event.target.value, 10))}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}
