import * as React from 'react';

import { config } from '../../config';
import { CheckInForm } from '../../components/check-in/check-in-form';

export const metadata = { title: `Check in | ${config.site.name}` };

export default function Page() {
  return (
    <CheckInForm />
  );
}
