import React from 'react';
import { paths } from '../../../paths';
import { useTranslation } from 'react-i18next';

const NavItems = () => {
  const { t } = useTranslation();

  const navItems = [
    { key: 'overview', title: t('overview'), href: paths.app.dashboard, icon: 'chart-pie' },
    { key: 'reservations', title: t('reservations'), href: paths.app.reservations, icon: 'calendar' },
    { key: 'properties', title: t('properties'), href: paths.app.properties, icon: 'house' },
    { key: 'forms', title: t('forms'), href: paths.app.forms, icon: 'clipboardText' },
    { key: 'integrations', title: t('integrations'), href: paths.app.integrations, icon: 'plugs-connected' },
  ];

  return navItems;
};

export default NavItems;