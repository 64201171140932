import * as React from 'react';
import { useParams } from "react-router-dom"

import { config } from '../../../../config';
import { FormEdit } from '../../../../components/app/form/form-edit';

export const metadata = { title: `Edit form | Dashboard | ${config.site.name}` };

export default function Page() {
  const { id } = useParams();
  return (
    <FormEdit id={id}/>
  );
}
