'use client';

import * as React from 'react';
// import { useServerInsertedHTML } from 'next/navigation';
import createCache from '@emotion/cache';
import { CacheProvider as DefaultCacheProvider } from '@emotion/react';

// Adapted from https://github.com/garronej/tss-react/blob/main/src/next/appDir.tsx
export default function NextAppDirEmotionCacheProvider(props){
  const { options, CacheProvider = DefaultCacheProvider, children } = props;

  const [registry] = React.useState(() => {
    const cache = createCache(options);
    cache.compat = true;
    // eslint-disable-next-line @typescript-eslint/unbound-method -- Expected
    const prevInsert = cache.insert;
    let inserted= [];
    cache.insert = (...args) => {
      const [selector, serialized] = args;

      if (cache.inserted[serialized.name] === undefined) {
        inserted.push({ name: serialized.name, isGlobal: !selector });
      }

      return prevInsert(...args);
    };
    const flush = () => {
      const prevInserted = inserted;
      inserted = [];
      return prevInserted;
    };
    return { cache, flush };
  });

  // useServerInsertedHTML(() => {
  //   const inserted = registry.flush();

  //   if (inserted.length === 0) {
  //     return null;
  //   }

  //   let styles = '';
  //   let dataEmotionAttribute = registry.cache.key;

  //   const globals = [];

  //   inserted.forEach(({ name, isGlobal }) => {
  //     const style = registry.cache.inserted[name];

  //     if (typeof style !== 'boolean') {
  //       if (isGlobal) {
  //         globals.push({ name, style });
  //       } else {
  //         styles += style;
  //         dataEmotionAttribute += ` ${name}`;
  //       }
  //     }
  //   });

  //   return (
  //     <React.Fragment>
  //       {globals.map(
  //         ({ name, style }) => (
  //           <style
  //             dangerouslySetInnerHTML={{ __html: style }}
  //             data-emotion={`${registry.cache.key}-global ${name}`}
  //             key={name}
  //           />
  //         )
  //       )}
  //       {styles ? <style dangerouslySetInnerHTML={{ __html: styles }} data-emotion={dataEmotionAttribute} /> : null}
  //     </React.Fragment>
  //   );
  // });

  return <CacheProvider value={registry.cache}>{children}</CacheProvider>;
}
