import { ChartPie as ChartPieIcon } from '@phosphor-icons/react/dist/ssr/ChartPie';
import { GearSix as GearSixIcon } from '@phosphor-icons/react/dist/ssr/GearSix';
import { PlugsConnected as PlugsConnectedIcon } from '@phosphor-icons/react/dist/ssr/PlugsConnected';
import { User as UserIcon } from '@phosphor-icons/react/dist/ssr/User';
import { CalendarDots as CalendarDotsIcon } from '@phosphor-icons/react/dist/ssr/CalendarDots';
import { House as HouseIcon } from '@phosphor-icons/react/dist/ssr/House';
import { ClipboardText } from '@phosphor-icons/react/dist/ssr/ClipboardText';
import { Users as UsersIcon } from '@phosphor-icons/react/dist/ssr/Users';

import { XSquare } from '@phosphor-icons/react/dist/ssr/XSquare';

export const navIcons = {
  'chart-pie': ChartPieIcon,
  'gear-six': GearSixIcon,
  'plugs-connected': PlugsConnectedIcon,
  'x-square': XSquare,
  user: UserIcon,
  calendar: CalendarDotsIcon,
  users: UsersIcon,
  house: HouseIcon,
  clipboardText: ClipboardText
};
