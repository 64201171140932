import { 
    ApiError,
  get, post, put } from "aws-amplify/api"
import { amplifyClient } from '../amplify';

amplifyClient.configure()

class IntegrationClient {

  async load(tenant_id, integration_id) {
    var getOutput = null
    var error = ''

    try {
         const getOperation = get({
          apiName: 'integrations',
          path: '/{id}',
          options: {
            queryParams: {
              tenant_id: tenant_id,
              integration_id: integration_id
            },
           
          },

        })

        getOutput = await getOperation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: getOutput, error: error };
  }

  async create(tenant_id, integration_id, configuration) {
    var output = null
    var error = ''

    try {
         const operation = post({
          apiName: 'integrations',
          path: '/',
          options: {
            body: { 
              tenant_id: tenant_id, 
              integration_id: integration_id,
              configuration: configuration
            }
          }
        })

        output = await operation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: output, error: error };
  }


  async update(tenant_id, integration_id, configuration) {
    var output = null
    var error = ''

    try {
         const operation = put({
          apiName: 'integrations',
          path: '/',
          options: {
            body: { 
              tenant_id: tenant_id, 
              integration_id: integration_id,
              configuration: configuration
            }
          }
        })

        output = await operation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: output, error: error };
  }
}

export const integrationClient = new IntegrationClient();
