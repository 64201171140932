import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react/dist/ssr/Download';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Upload as UploadIcon } from '@phosphor-icons/react/dist/ssr/Upload';
import dayjs from 'dayjs';
import Grid from '@mui/material/Unstable_Grid2';

import { config } from '../../../../config';
import { PropertyCreate } from '../../../../components/app/property/property-create';

export const metadata = { title: `Create property | Dashboard | ${config.site.name}` } ;

export default function Page() {
  return (
    <PropertyCreate/>
  );
}