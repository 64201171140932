import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { Pen as PenIcon } from '@phosphor-icons/react/dist/ssr/Pen';
import { useTranslation } from 'react-i18next';

import { useSelection } from '../../../../hooks/use-selection';
import { paths } from '../../../../paths';

function noop() {
  // do nothing
}

export function FormsTable({
  count = 0,
  rows = [],
  page = 0,
  rowsPerPage = 0,
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
}) {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <Card>
      <Box sx={{ overflowX: 'auto' }}>
        <Table sx={{ minWidth: '800px' }}>
          <TableHead>
            <TableRow>
            <TableCell>{t("form.name")}</TableCell>
            <TableCell>{t("form.action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow hover key={row.property_id + index} >
                  <TableCell>
                    <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
                      <Typography variant="subtitle2">{row.form_name}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Button 
                      startIcon={<PenIcon fontSize="var(--icon-fontSize-md)" />} 
                      variant="outlined"
                      onClick={() => {
                        navigate(paths.app.forms + "/" + row.form_id)
                      }}
                    >
                      {t("form.edit")}
                    </Button>

                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <TablePagination
        component="div"
        page={page}
        rowsPerPage={rowsPerPage}
        count={count}
        onPageChange={(event, newPage) => onPageChange(newPage)}
        onRowsPerPageChange={(event) => onRowsPerPageChange(parseInt(event.target.value, 10))}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}
