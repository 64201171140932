import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react/dist/ssr/Download';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Upload as UploadIcon } from '@phosphor-icons/react/dist/ssr/Upload';
import dayjs from 'dayjs';

import { useProperty } from '../../../hooks/use-property';

import { config } from '../../../config';
import { ReservationFilter } from '../../../components/app/reservation/components/reservation-filter';
import { ReservationTable } from '../../../components/app/reservation/components/reservation-table';
import { paths } from '../../../paths';
import { useTranslation } from 'react-i18next';

export const metadata = { title: `Reservations | Dashboard | ${config.site.name}` };

export function Reservation() {
  const navigate = useNavigate();
  const {t} = useTranslation();

    const { listReservations, listProperties } = useProperty();

    const [reservations, setReservations] = React.useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5); 

    const paginated = applyPagination(reservations, page, rowsPerPage);

    React.useEffect(() => {
      console.log('i fire once');
      
      async function fetchData() {
        const { data, error } = await listReservations()
        setReservations(await data.body.json())
      }

      fetchData()
    }, []);

    const handlePageChange = (newPage) => {
      setPage(newPage);
    };

    return (
        <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
          <Typography variant="h4">{t("reservations")}</Typography>
        </Stack>
        <div>
          <Button 
            startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} 
            variant="contained"
            onClick={() => {
              navigate(paths.app.reservations + "/create")
            }}
          >
            {t("reservation.add")}
          </Button>
        </div>
      </Stack>
      {/* <ReservationFilter /> */}
      <ReservationTable
          count={reservations.length}
          page={page}
          rows={paginated}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={(newRowsPerPage) => {
            setRowsPerPage(newRowsPerPage);
            setPage(0); // Reset to first page when changing rows per page
          }}
        />
    </Stack>
  );
}

function applyPagination(rows, page, rowsPerPage) {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}
