import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react/dist/ssr/Download';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Upload as UploadIcon } from '@phosphor-icons/react/dist/ssr/Upload';
import dayjs from 'dayjs';

import { useProperty } from '../../../hooks/use-property';

import { config } from '../../../config';
import { FormsFilters } from './components/form-filters';
import { FormsTable } from './components/form-table';
import { paths } from '../../../paths';
import { useTranslation } from 'react-i18next';

export const metadata = { title: `Properties | Dashboard | ${config.site.name}` };

export function Form() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const { listForms } = useProperty();

    const [forms, setForms] = React.useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5); 

    const paginated = applyPagination(forms, page, rowsPerPage);

    React.useEffect(() => {
      async function fetchData() {
        const { data, error } = await listForms()

        setForms(await data.body.json())
      }
        
      fetchData()
    }, []);

    return (
        <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
        <Typography variant="h4">{t("forms")}</Typography>
        </Stack>
        <div>
          <Button 
            startIcon={<PlusIcon fontSize="var(--icon-fontSize-md)" />} 
            variant="contained"
            onClick={() => {
              navigate(paths.app.forms + "/create")
            }}
          >
            {t("form.add")}
          </Button>
        </div>
      </Stack>
      {/* <FormsFilters /> */}
      <FormsTable
        count={forms.length}
        page={page}
        rows={paginated}
        rowsPerPage={rowsPerPage}
        onPageChange={setPage}
        onRowsPerPageChange={(newRowsPerPage) => {
          setRowsPerPage(newRowsPerPage);
          setPage(0); // Reset to first page when changing rows per page
        }}
      />
    </Stack>
  );
}

function applyPagination(rows, page, rowsPerPage) {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}
