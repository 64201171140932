import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Download as DownloadIcon } from '@phosphor-icons/react/dist/ssr/Download';
import { Plus as PlusIcon } from '@phosphor-icons/react/dist/ssr/Plus';
import { Upload as UploadIcon } from '@phosphor-icons/react/dist/ssr/Upload';
import dayjs from 'dayjs';
import Grid from '@mui/material/Unstable_Grid2';

import { useProperty } from '../../../hooks/use-property';

import { config } from '../../../config';
import { FormInfo } from '../../../components/app/form/components/form-info';
import { FormDetailsForm } from '../../../components/app/form/components/form-details-form';
import { z as zod } from 'zod';
import { useTranslation } from 'react-i18next';

export const metadata = { title: `Edit property | Dashboard | ${config.site.name}` };

export function FormEdit({
  id = ""
}) {
  const {t} = useTranslation();

    const { loadForm } = useProperty();

    const [form, setForm] = React.useState()

    React.useEffect(() => {
      async function fetchData() {
        const { data, error } = await loadForm(id)

        console.log((await data.body.json())[0])

        const form = (await data.body.json())[0]

        console.log(form)
        setForm(form)
      }
        
      fetchData()
    }, []);

    return (
      <Stack spacing={3}>
      <div>
        <Typography variant="h4">{t("form")}</Typography>
      </div>
      <Grid container spacing={3}>
        {/* <Grid lg={4} md={6} xs={12}>
          <FormInfo form={form} />
        </Grid> */}
        <Grid lg={12} md={12} xs={12}>
          <FormDetailsForm form={form} />
        </Grid>
      </Grid>
    </Stack>
  );
}

function applyPagination(rows, page, rowsPerPage) {
  return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
}
