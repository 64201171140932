import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { GearSix as GearSixIcon } from '@phosphor-icons/react/dist/ssr/GearSix';
import { SignOut as SignOutIcon } from '@phosphor-icons/react/dist/ssr/SignOut';
import { User as UserIcon } from '@phosphor-icons/react/dist/ssr/User';
import { useTranslation } from "react-i18next";

import { paths } from '../../../paths';
import { authClient } from '../../../lib/auth/client';
import { logger } from '../../../lib/default-logger';
import { useUser } from '../../../hooks/use-user';

export function LanguagePopover({ anchorEl, onClose, open }) {
  const { checkSession, user } = useUser();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const handleSignOut = React.useCallback(async () => {
    try {
      const { error } = await authClient.signOut();

      if (error) {
        logger.error('Sign out error', error);
        return;
      }

      // Refresh the auth state
      await checkSession?.();

      // UserProvider, for this case, will not refresh the router and we need to do it manually
      navigate(0);
      // After refresh, AuthGuard will handle the redirect
    } catch (err) {
      logger.error('Sign out error', err);
    }
  }, [checkSession, navigate]);

  let countries = [
    {
      code: "pt",
      name: "Português",
      country_code: "pt",
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
  ];

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      onClose={onClose}
      open={open}
      slotProps={{ paper: { sx: { width: '150px' } } }}
    >
      <MenuList disablePadding sx={{ p: '8px', '& .MuiMenuItem-root': { borderRadius: 1 } }}>
        {/* SETTING AND PROFILE PAGES EXIST BUT I HAVE DISABLED THEM FOR AN INITIAL PROJECT */}
        {/* <MenuItem href={paths.dashboard.settings} onClick={onClose}>
          <ListItemIcon>
            <GearSixIcon fontSize="var(--icon-fontSize-md)" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem href={paths.dashboard.account} onClick={onClose}>
          <ListItemIcon>
            <UserIcon fontSize="var(--icon-fontSize-md)" />
          </ListItemIcon>
          Profile
        </MenuItem> */}
        {countries.map((lng) => {
          return (
            <MenuItem
              key={lng.code}
              onClick={() => i18n.changeLanguage(lng.code)}
              disabled={i18n.language === lng.code}
            > 
              {lng.name}
            </MenuItem>
          );
        })}
      </MenuList>
    </Popover>
  );
}
