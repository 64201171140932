import * as React from 'react';
import { config } from '../../../config';
import { Reservation } from '../../../components/app/reservation/reservation';

export const metadata = { title: `Reservations | Dashboard | ${config.site.name}` };

export default function Page() {
  return (
    <Reservation/>
  );
}