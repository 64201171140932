import * as React from 'react';
import { config } from '../../../config';
import { Form } from '../../../components/app/form/form';

export const metadata = { title: `Properties | Dashboard | ${config.site.name}` }

export default function Page() {
  return (
    <Form/>
  );
}