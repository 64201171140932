import * as React from 'react';
import { useParams } from "react-router-dom"

import { config } from '../../../../config';
import { IntegrationEdit } from '../../../../components/app/integration/integration-edit';

export const metadata = { title: `Edit integration | Dashboard | ${config.site.name}` };

export default function Page() {
  const { id } = useParams();
  return (
    <IntegrationEdit id={id}/>
  );
}
