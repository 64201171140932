import * as React from 'react';
import { Routes, Route, Outlet, Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import GlobalStyles from '@mui/material/GlobalStyles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { PropertyProvider } from '../../contexts/property-context';
import { GuestGuard } from '../../components/auth/guest-guard';
import { paths } from '../../paths';
import { DynamicLogo } from '../../components/core/logo';

export default function Layout({ children }) {
    return (
        <GuestGuard>
            <Box
                sx={{
                    display: { xs: 'flex', lg: 'grid' },
                    flexDirection: 'column',
                    gridTemplateColumns: '1fr 1fr',
                    minHeight: '100%'
                }}
            >
                <Box sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>
                    <Box sx={{ p: 3 }}>
                        {/* <Box component={Router} href={paths.home} sx={{ display: 'inline-block', fontSize: 0 }}> */}
                        <Box href={paths.home} sx={{ display: 'inline-block', fontSize: 0 }}>
                            <DynamicLogo colorDark="light" colorLight="dark" height={64} width={64} />
                        </Box>
                    </Box>
                    <Box sx={{ alignItems: 'center', display: 'flex', flex: '1 1 auto', justifyContent: 'center', p: 3 }}>
                        <Box sx={{ maxWidth: '450px', width: '100%' }}>
                            {/* {children} */}
                            <Outlet />
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        alignItems: 'center',
                        background: 'radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)',
                        color: 'var(--mui-palette-common-white)',
                        display: { xs: 'none', lg: 'flex' },
                        justifyContent: 'center',
                        p: 3,
                    }}
                >
                    <Stack spacing={3}>
                        <Stack spacing={1}>
                            <Typography color="inherit" sx={{ fontSize: '24px', lineHeight: '32px', textAlign: 'center' }} variant="h1">
                                Welcome to{' '}
                                <Box component="span" sx={{ color: '#15b79e' }}>
                                    Airbnbuilding
                                </Box>
                            </Typography>
                            <Typography align="center" variant="subtitle1">
                                Enhance your Airbnb with a smart check-in flow that simplifies guest arrivals and integrates with smart devices for a seamless self-check-in experience.
                            </Typography>
                        </Stack>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box
                                component="img"
                                alt="Widgets"
                                src="/assets/auth-widgets.png"
                                sx={{ height: 'auto', width: '100%', maxWidth: '600px' }}
                            />
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </GuestGuard>
    );
}