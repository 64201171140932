'use client';

import { 
    ApiError,
  get, post, put } from "aws-amplify/api"
import { amplifyClient } from '../amplify';

amplifyClient.configure()

class FormClient {
  
  async list(tenant_id) {
    var getOutput = null
    var error = ''

    try {
         const getOperation = get({
          apiName: 'forms',
          path: '/',
          options: {
            queryParams: {
              tenant_id: tenant_id
            }
          }
        })

        getOutput = await getOperation.response

        console.log(getOutput)
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: getOutput, error: error };
  }

  async load(tenant_id, form_id) {
    var getOutput = null
    var error = ''

    try {
         const getOperation = get({
          apiName: 'forms',
          path: '/{id}',
          options: {
            queryParams: {
              tenant_id: tenant_id,
              form_id: form_id
            },
           
          },

        })

        getOutput = await getOperation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: getOutput, error: error };
  }

  async create(tenant_id, form_name, custom_fields) {
    var output = null
    var error = ''

    try {
         const operation = post({
          apiName: 'forms',
          path: '/',
          options: {
            body: { 
              tenant_id: tenant_id, 
              form_name: form_name,
              custom_fields: custom_fields,
            }
          }
        })

        output = await operation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: output, error: error };
  }


  async update(tenant_id, form_id, form_name, custom_fields) {
    var output = null
    var error = ''

    try {
         const operation = put({
          apiName: 'forms',
          path: '/',
          options: {
            body: { 
              tenant_id: tenant_id, 
              form_id: form_id,
              form_name: form_name,
              custom_fields: custom_fields
            }
          }
        })

        output = await operation.response
    } catch (err) {
        console.log(err)
        error = String(err)
    }
    
    return { data: output, error: error };
  }
}

export const formClient = new FormClient();
