'use client';

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import CheckBox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/Delete';

import { z as zod } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';

import { useProperty } from '../../../../hooks/use-property';
import { paths } from '../../../../paths';

const schema = zod.object({
  form_name: zod.string().min(1, { message: 'Form name is required' }),
});

export function FormDetailsForm({
  form
}) {
  const navigate = useNavigate();
  const {t} = useTranslation()

  const { createForm, updateForm } = useProperty();

  const [customFields, setCustomFields] = React.useState([]);

  const [isPending, setIsPending] = React.useState(false);

  const initialValues = {
    form_name: form?.form_name ?? "",
  }

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ values: initialValues, resolver: zodResolver(schema) });

  React.useEffect(() => {
    console.log('1 Invoke')
    if (form?.custom_fields) {
      setCustomFields(form.custom_fields);
    }
  }, [form]);

  const addCustomField = () => {
    setCustomFields([
      ...customFields,
      { name: '', type: 'text', required: { value: false, message: '' } }, // Default new field
    ]);
  };

  const handleFieldChange = (index, key, value) => {
    const updatedFields = [...customFields];
    updatedFields[index][key] = value;
    setCustomFields(updatedFields);
  };

  const removeField = (index) => {
    const updatedFields = customFields.filter((_, i) => i !== index);
    setCustomFields(updatedFields);
  };

  const onSubmit = React.useCallback(
    async (values) => {
      setIsPending(true);

      const { data, error } = form ? 
        await updateForm(
          form.form_id, 
          values.form_name,
          customFields
        )
      : 
        await createForm(
          values.form_name,
          customFields,
        )

      if (error) {
        setError('root', { type: 'server', message: error });
        setIsPending(false);
        return;
      }

      navigate(paths.app.forms)
    },
    [form, customFields, navigate, setError]
  );


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader subheader={t("form.sub_header")} title={t("form")} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>

            <Grid md={7} xs={12}>
              <Controller
                control={control}
                name="form_name"
                render={({ field }) => (
                  <FormControl fullWidth error={Boolean(errors.form_name)}>
                    <InputLabel>{t("form.form_name")}</InputLabel>
                    <OutlinedInput {...field} label={t("form.form_name")} />
                    {errors.form_name ? <FormHelperText>{errors.form_name.message}</FormHelperText> : null}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid md={12} xs={12}>
              <Divider variant="middle">{t("form")}</Divider>
            </Grid>

            <Grid xs={12} spacing={3} sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3 }}>
              {/* Left Column: Form Preview */}
              <Grid item xs={12}>
                <Typography variant="h6">{t("form.preview")}</Typography>
                <Divider sx={{ my: 2 }} />
                <Box
                  sx={{
                    border: '1px solid #ddd',
                    padding: 2,
                    borderRadius: 2,
                    minHeight: '300px',
                  }}
                >
                  {customFields?.length > 0 ? (
                    customFields?.map((field, index) => (
                      <Box key={index} sx={{ mb: 2 }}>
                        {field.type === 'text' &&
                          <FormControl fullWidth disabled>
                            <InputLabel>{field.name} {field.required.value && <span style={{ color: 'red' }}>*</span>}</InputLabel>
                            <OutlinedInput
                              value={field.name}
                              label={field.required.value ? field.name + " *" : field.name}                            
                            />
                        </FormControl>
                        }
                        {field.type === 'number' && (
                          <FormControl disabled>
                            <InputLabel>{field.name} {field.required.value && <span style={{ color: 'red' }}>*</span>}</InputLabel>
                            <OutlinedInput
                              value={field.name}
                              label={field.required.value ? field.name + " *" : field.name}
                            />
                          </FormControl>
                        )}
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      {t("form.preview.sub_header")}
                    </Typography>
                  )}
                </Box>
              </Grid>

              {/* Right Column: Field Customization */}
              <Grid item xs={12}>
                <Typography variant="h6">{t("form.fields")}</Typography>
                <Divider sx={{ my: 2 }} />

                {/* Dynamic Input Fields */}
                {customFields?.map((field, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={9} md={10}>
                        <FormControl fullWidth>
                          <InputLabel>{t("form.field_name")}</InputLabel>
                          <OutlinedInput
                            value={field.name}
                            onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                            label={t("form.field_name")}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={2} md={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          fullWidth
                          onClick={() => removeField(index)}
                        >
                          {t("form.delete_field")}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                      <Grid item xs={7} md={6}>
                        <FormControl fullWidth>
                          <InputLabel>{t("form.field_type")}</InputLabel>
                          <Select
                            value={field.type}
                            label={t("form.field_type")}
                            onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
                          >
                            <MenuItem value="text">{t("form.field_type.text")}</MenuItem>
                            <MenuItem value="number">{t("form.field_type.number")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={5} md={6}>
                        <FormControlLabel
                          control={
                            <CheckBox
                              checked={field.required.value}
                              onChange={(e) => handleFieldChange(index, 'required', { value: e.target.checked })}
                            />
                          }
                          label={t("form.field_required")}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                ))}

                {/* Add Field Button */}
                <Button variant="contained" onClick={addCustomField}>
                {t("form.add_field")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {errors.root ? <Alert color="error">{errors.root.message}</Alert> : null}

        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" >{t("form.save")}</Button>
        </CardActions>
      </Card>
    </form>
  );
}




// <Card>
// <CardHeader subheader="The information can be edited" title="Form" />
// <Divider />
// <CardContent>
//   <Grid container spacing={3} sx={{ maxWidth: 'sm' }}>
//     <Grid md={12} xs={12}>
//       <Controller
//         control={control}
//         name="form_name"
//         render={({ field }) => (
//           <FormControl fullWidth error={Boolean(errors.form_name)}>
//             <InputLabel>Form name</InputLabel>
//             <OutlinedInput {...field} label="Form name" />
//             {errors.form_name ? <FormHelperText>{errors.form_name.message}</FormHelperText> : null}
//           </FormControl>
//         )}
//       />
//     </Grid>
//     <Grid md={12} xs={12}>
//       <Divider variant="middle">Form</Divider>
//     </Grid>

//     <Grid md={12} xs={12}>
//       <Controller
//         control={control}
//         name="address.street"
//         render={({ field }) => (
//           <FormControl fullWidth error={Boolean(errors.address?.street)}>
//             <InputLabel>Street address</InputLabel>
//             <OutlinedInput {...field} label="Street address" />
//             {errors.address?.street ? <FormHelperText>{errors.address?.street.message}</FormHelperText> : null}
//           </FormControl>
//         )}
//       />
//     </Grid>
//   </Grid>
//   {errors.root ? <Alert color="error">{errors.root.message}</Alert> : null}

// </CardContent>
// <Divider />
// <CardActions sx={{ justifyContent: 'flex-end' }}>
//   <Button variant="contained" type="submit" >Save details</Button>
// </CardActions>
// </Card>