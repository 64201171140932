import * as React from 'react';
import { useParams } from "react-router-dom"

import { config } from '../../../../config';
import { ReservationEdit } from '../../../../components/app/reservation/reservation-edit';

export const metadata = { title: `Edit reservation | Dashboard | ${config.site.name}` };

export default function Page(){
  const { id } = useParams();

  return (
    <ReservationEdit id={id}/>
  );
}
